/* eslint jsx-a11y/accessible-emoji: 0 */
import axios from "axios"
import React, { useEffect } from "react"
import { Link } from "gatsby"
import useTranslation from "src/hooks/useTranslation"

import { FiArrowLeft } from "react-icons/fi"

import "./success.scss"

const donationGifs = [
  "https://media.giphy.com/media/l0MYt5jPR6QX5pnqM/giphy.gif",
  "https://media.giphy.com/media/l3q2wJsC23ikJg9xe/giphy.gif",
  "https://media.giphy.com/media/hEIuLmpW9DmGA/giphy.gif",
  "https://media.giphy.com/media/x2PDDW8WUX9uM/giphy.gif",
]

const lifetimeGifs = [
  "https://media1.giphy.com/media/14cDsqOkks6O8U/giphy.gif",
  "https://media1.giphy.com/media/gOkawaguYNiSI/giphy.gif",
]

const subscriptionGifs = [
  "https://media1.giphy.com/media/14cDsqOkks6O8U/giphy.gif",
  "https://media1.giphy.com/media/OcZp0maz6ALok/giphy.gif",
]

const randomGif = source => source[Math.floor(Math.random() * source.length)]

const ShareSection = ({ twitterMessage }) => {
  return (
    <div className="success__share">
      Share our story on{" "}
      <a
        href="https://www.facebook.com/sharer/sharer.php?u=https://getstack.app/supporters"
        target="_blank"
      >
        Facebook
      </a>{" "}
      or{" "}
      <a
        href={`https://twitter.com/intent/tweet?text=${twitterMessage}%20https://getstack.app/supporters%20@Stack_HQ`}
        target="_blank"
      >
        Twitter
      </a>
    </div>
  )
}

const DonatorScreen = ({ location }) => {
  const isKa = location.pathname.startsWith("/ka")
  const redirect = isKa ? "/ka/supporters" : "/supporters"

  return (
    <div className="success container container--sm">
      <h1 className="about-us__title mb-3 mb-md-5 pb-0 pb-md-2">Thank you</h1>

      <img className="success__gif" src={randomGif(donationGifs)} alt="" />

      <p className="mt-3">Your support means everything to us! 🙏</p>

      <p className="mt-3">
        Shortly, you will receive an email confirming that we have received your
        contribution.
      </p>

      <p className="mt-3">
        Meanwhile, we keep working day and night to design the flawless remote
        work environment for you. ❤️
      </p>

      <ShareSection twitterMessage="The Stack story" />

      <Link to={redirect}>
        <button className="success__button se-button mb-5">
          <FiArrowLeft className="mr-2" />
          Back to Supporters page
        </button>
      </Link>
    </div>
  )
}

const LifetimeScreen = ({ location }) => {
  const isKa = location.pathname.startsWith("/ka")
  const redirect = isKa ? "/ka/supporters" : "/supporters"
  return (
    <div className="success container container--sm">
      <h1 className="about-us__title mb-3 mb-md-5 pb-0 pb-md-2">
        Congratulations!
      </h1>

      <img className="success__gif" src={randomGif(lifetimeGifs)} alt="" />

      <h2 className="mt-3">
        You have just boarded our ship for the lifetime trip! 🏂
      </h2>

      <p className="mt-3">
        You are one of the 500 people in the world, who will have Stack PRO for
        lifetime.
      </p>

      <p className="mt-3">Spread the word and tell others about it!</p>

      <ShareSection twitterMessage="I just became one of the 500 Lifetime Stackers 🤩" />

      <h1 className="about-us__title mb-3 mt-5 mb-md-5 pb-0 pb-md-2">
        What's next?
      </h1>

      <p>
        <a href="https://getstack.app"> Download Stack </a>
        and authorize with the same e-mail by clicking the head shaped icon in
        the bottom left corner!
        <img
          src="https://i.imgur.com/vDlXQV1.png"
          alt=""
          className="success__img"
        />
      </p>

      <p>
        Check out this{" "}
        <a href="https://getstack.app/blog/how-to-use-stack-like-a-true-stacker?date=1592695965065">
          blog post
        </a>{" "}
        to learn more on what you can do with Stack.
      </p>

      <p>Thanks for being awesome! 😍</p>

      <Link to={redirect}>
        <button className="success__button se-button mb-5">
          <FiArrowLeft className="mr-2" />
          Back to Supporters page
        </button>
      </Link>
    </div>
  )
}

const SubscriberScreen = ({ location }) => {
  const isKa = location.pathname.startsWith("/ka")
  const redirect = isKa ? "/ka/supporters" : "/supporters"
  return (
    <div className="success container container--sm">
      <h1 className="about-us__title mb-3 mb-md-5 pb-0 pb-md-2">
        Congratulations!
      </h1>

      <img className="success__gif" src={randomGif(subscriptionGifs)} alt="" />

      <p className="mt-3"> You are Stack PRO now! </p>

      <p className="mt-3">Spread the word and tell others about it!</p>

      <ShareSection twitterMessage="I just became one of the 500 Lifetime Stackers 🤩" />

      <h1 className="about-us__title mb-3 mt-5 mb-md-5 pb-0 pb-md-2">
        What's next?
      </h1>

      <p>
        <a href="https://getstack.app"> Download Stack </a>
        and authorize with the same e-mail by clicking the head shaped icon in
        the bottom left corner!
        <img
          src="https://i.imgur.com/vDlXQV1.png"
          alt=""
          className="success__img"
        />
      </p>

      <p>
        Check out this{" "}
        <a href="https://getstack.app/blog/how-to-use-stack-like-a-true-stacker?date=1592695965065">
          blog post
        </a>{" "}
        to learn more on what you can do with Stack.
      </p>

      <p>Thanks for being awesome! 😍</p>

      <Link to={redirect}>
        <button className="success__button se-button mb-5">
          <FiArrowLeft className="mr-2" />
          Back to Supporters page
        </button>
      </Link>
    </div>
  )
}

const Success = ({ location }) => {
  const affiliate = async () => {
    try {
      const sessionId = new URLSearchParams(location.search).get("sessionId")

      const {
        data: { data },
      } = await axios.get(`${process.env.GATSBY_SERVER_URL}/support/session/`, {
        params: { sessionId },
      })
      if (data.chargeId && window.tap) {
        window.tap("conversion", data.chargeId, data.amount, {
          customer_id: data.customerId,
          meta_data: {
            email: data.email,
            userId: data.userId,
          }
        }, data.commissionType)
      }
    } catch (error) {
      console.error("affiliate", error)
    }
  }

  const planType = new URLSearchParams(location.search).get("plan_type")

  useEffect(() => {
    affiliate()
    if (window.Stack) {
      window.Stack.Auth.notifyUserSubscriptionHasChanged()
    }
  }, [])

  if (planType === "lifetime") {
    return <LifetimeScreen location={location} />
  }

  if (planType === "subscription") {
    return <SubscriberScreen location={location} />
  }

  return <DonatorScreen location={location} />
}

export default Success
